<template>
  <v-container>
    <div class="ml-2">
      <PageTitle class="font" text="Product Deliveries" />
      <div class="d-flex flex-row flex-grow-1 justify-end pr-3 pb-3">
        <v-chip-group
          light
          v-model="isDeliveryAccepted"
          show-arrows
          class="font "
        >
          <v-chip
            label
            color="white"
            mandatory
            class=" box-shadow-light"
            v-for="(state, index) in deliveryState"
            :value="state.value"
            :key="index"
            active-class="primary--text"
          >
            <span class="font font-weight-medium">{{
              state.text
            }}</span></v-chip
          >
        </v-chip-group>
        <v-chip
          v-if="date"
          class="mr-2"
          style="margin-top: 14px;"
          label
          small
          color="primary"
          @click:close="clearFilters"
          close
        >
          <span class="font font-weight-medium">Clear Filter</span>
        </v-chip>
        <span
          v-else
          class="font d-flex flex-row font-weight-medium text-uppercase pr-3 mt-1"
          style="align-items: center;"
          >Filter</span
        >

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          :nudge-left="170"
          offset-y
          min-width="auto"
          class="ft font-weight-medium  "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="font mt-2 font-weight-medium "
              outlined
              v-bind="attrs"
              v-on="on"
              min-width="120px"
            >
              <span class="ft">{{ date ? date : "Filter By Date" }}</span>
            </v-btn>
          </template>
          <v-date-picker
            class="font font-weight-medium  "
            v-model="date"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="deliveries"
              :loading="isListLoading"
              :options.sync="options"
              item-key="name"
              class="box-shadow-light font "
            >
              <template #item.date="{item}">
                <span class="font font-weight-medium">{{
                  item.date | timestamp("Do MMM, YYYY")
                }}</span>
              </template>
              <template #item.productId="{item}">
                <v-chip label small color="primary">
                  <span class="font font-weight-bold">{{
                    item.productId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.isDeliveryAccepted="{item}">
                <v-chip
                  label
                  :color="
                    item.isDeliveryAccepted ? '#9efb9e66' : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isDeliveryAccepted
                        ? 'rgba(3, 91, 3, 0.83)'
                        : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >{{
                      item.isDeliveryAccepted ? "Confirmed" : "Pending"
                    }}</span
                  >
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('details', 'deliveries/details', item.id)"
                      fab
                      icon
                      :disabled="item.isDeliveryAccepted"
                      small
                      v-on="on"
                      color="primary"
                    >
                      <i class="material-icons-outlined font-size-md"
                        >mark_email_read</i
                      >
                    </v-btn>
                  </template>
                  <span class="font font-weight-medium font-size-sm"
                    >Confirm Delivery</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <ProductDeliveryConfirmations
      :state="dialog('details')"
      @actions="close"
      :loading="loading"
      @confirmDeliveries="confirmDeliveries"
      :details="deliveryDetails"
    />
    <v-snackbar v-model="showSnackBar" :timeout="3000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import { mapGetters } from "vuex";
import { timestamp } from "../../filters/timestamp";
import DialogMixins from "../../mixins/DialogMixins";
export default {
  name: "ProductDeliveries",
  components: {
    PageTitle,
    ProductDeliveryConfirmations: () =>
      import(`./dialogs/ProductDeliveryConfirmation`)
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Product", value: "productId" },
        { text: "Quantity (Litres)", value: "quantity" },
        { text: "Delivery Status", value: "isDeliveryAccepted" },
        { text: "Actions", value: "actions" }
      ],
      tags: [],
      tag: null,
      menu: false,
      deliveryState: [
        { text: "Pending Deliveries", value: false },
        { text: "Accepted Deliveries", value: true }
      ],
      date: "",
      isDeliveryAccepted: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      }
    };
  },
  mixins: [DialogMixins],
  filters: {
    timestamp
  },
  computed: {
    ...mapGetters({
      deliveries: "deliveries/getAllDeliveries",
      pagination: "deliveries/getPagination",
      isListLoading: "getIsListLoading",
      loading: "getIsLoading",
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      deliveryDetails: "deliveries/getDeliveryDetails"
    })
  },
  watch: {
    date(value) {
      this.$store.dispatch("deliveries/listDeliveries", {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        isDeliveryAccepted: this.isDeliveryAccepted,
        date: value
      });
    },
    isDeliveryAccepted(value) {
      this.$store.dispatch("deliveries/listDeliveries", {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        isDeliveryAccepted: value,
        date: this.date
      });
    },
    options: {
      handler(payload) {
        this.$store.dispatch("deliveries/listDeliveries", {
          page: payload.page,
          itemsPerPage: payload.itemsPerPage,
          isDeliveryAccepted: this.isDeliveryAccepted,
          date: this.date
        });
      },
      deep: true
    }
  },
  methods: {
    clearFilters() {
      this.date = "";
    },
    confirmDeliveries(payload) {
      this.$store.dispatch("deliveries/confirmBranchDelivery", payload);
    }
  },
  created() {
    this.tags = new Array(12).fill(0).map((item, index) =>
      moment()
        .month(index)
        .format("MMMM YYYY")
    );

    this.$store.dispatch("deliveries/listDeliveries", {
      itemsPerPage: this.options.itemsPerPage,
      page: this.options.page,
      isDeliveryAccepted: this.isDeliveryAccepted,
      date: this.date
    });
  }
};
</script>
