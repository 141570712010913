var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font",attrs:{"text":"Product Deliveries"}}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end pr-3 pb-3"},[_c('v-chip-group',{staticClass:"font ",attrs:{"light":"","show-arrows":""},model:{value:(_vm.isDeliveryAccepted),callback:function ($$v) {_vm.isDeliveryAccepted=$$v},expression:"isDeliveryAccepted"}},_vm._l((_vm.deliveryState),function(state,index){return _c('v-chip',{key:index,staticClass:" box-shadow-light",attrs:{"label":"","color":"white","mandatory":"","value":state.value,"active-class":"primary--text"}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(state.text))])])}),1),(_vm.date)?_c('v-chip',{staticClass:"mr-2",staticStyle:{"margin-top":"14px"},attrs:{"label":"","small":"","color":"primary","close":""},on:{"click:close":_vm.clearFilters}},[_c('span',{staticClass:"font font-weight-medium"},[_vm._v("Clear Filter")])]):_c('span',{staticClass:"font d-flex flex-row font-weight-medium text-uppercase pr-3 mt-1",staticStyle:{"align-items":"center"}},[_vm._v("Filter")]),_c('v-menu',{staticClass:"ft font-weight-medium  ",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-left":170,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font mt-2 font-weight-medium ",attrs:{"outlined":"","min-width":"120px"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"ft"},[_vm._v(_vm._s(_vm.date ? _vm.date : "Filter By Date"))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"font font-weight-medium  ",on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light font ",attrs:{"headers":_vm.headers,"items":_vm.deliveries,"loading":_vm.isListLoading,"options":_vm.options,"item-key":"name"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font font-weight-medium"},[_vm._v(_vm._s(_vm._f("timestamp")(item.date,"Do MMM, YYYY")))])]}},{key:"item.productId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_c('span',{staticClass:"font font-weight-bold"},[_vm._v(_vm._s(item.productId.name))])])]}},{key:"item.isDeliveryAccepted",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isDeliveryAccepted ? '#9efb9e66' : 'rgb(247, 189, 172)',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isDeliveryAccepted
                      ? 'rgba(3, 91, 3, 0.83)'
                      : 'rgb(238, 78, 0)'
                  })},[_vm._v(_vm._s(item.isDeliveryAccepted ? "Confirmed" : "Pending"))])])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","icon":"","disabled":item.isDeliveryAccepted,"small":"","color":"primary"},on:{"click":function($event){return _vm.open('details', 'deliveries/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("mark_email_read")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Confirm Delivery")])])]}}])})],1)]],2)],1),_c('ProductDeliveryConfirmations',{attrs:{"state":_vm.dialog('details'),"loading":_vm.loading,"details":_vm.deliveryDetails},on:{"actions":_vm.close,"confirmDeliveries":_vm.confirmDeliveries}}),_c('v-snackbar',{attrs:{"timeout":3000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }